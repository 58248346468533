import { defineStore } from 'pinia';
// import { useFormNewStore } from '@/stores/formNew.js'
// const formNewStore = useFormNewStore()

export const useRegistoStore = defineStore('registo', {
    
    state: () => ({
        sessao: {}
    }),
    getters: {
        
    },
    actions: {
        fetchSessao(){
            axios.get('/sessao').then(response => {
                console.log('fetchSessao', response.data);
                this.sessao = response.data
                if(this.sessao) {
                    for (const [key, value] of Object.entries(this.sessao)) {
                        console.log(`${key}: ${value}`);
                    }
                }
            }).catch(error => {
                console.log('fetchSessaoError', error.response.data)
                
                // form.processing = false;
                // form.error = error.response.data.errors.password[0];
                // passwordInput.value.focus();
            });
        }
    },
    // persist: true,
})