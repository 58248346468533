import { defineStore } from 'pinia';

export const useEventoStore = defineStore('evento', {
    
    state: () => ({
        calendar: {},
        events: {},
        eventos: {}
    }),
    getters: {
        allSessions(state){
            const sessions = [];

            state.events.forEach(event => {
            event.filhos.forEach(filho => {
                filho.sessoes.forEach(sessao => {
                sessions.push(sessao);
                });
            });
            });

            return sessions;
        }
    },
    actions: {
        setEvents(events){
            this.eventos = events;
        },
        setCalendar(calendar){
            this.calendar = calendar;
        },
        fetchEvents(){
            axios.get('/events').then(response => {
                console.log('fetch', response.data);
                this.events = response.data
            }).catch(error => {
                console.log('fetchError', error.response.data)
                
                // form.processing = false;
                // form.error = error.response.data.errors.password[0];
                // passwordInput.value.focus();
            });
        },
        getSessoes(eventoId){
            return this.allSessions.filter((session) => session.evento_id == eventoId)
        }
    },
    // persist: true,
})