<template>
    <div id="Eventos">
        <div class="divide-y divide-neutral-200">
            <div v-for="(eventPai, index) in eventoStore.events" class="conteudo-mini">
                <div class="grid grid-cols-5 conteudo-gap-mini">
                    <div v-for="(event, indexEvent) in eventPai.filhos"
                        class="col-span-full grid grid-cols-5 shadow-lg rounded-3xl overflow-hidden">
                        <div class="relative col-span-full conteudo-mini grid grid-cols-2 sm:grid-cols-4 xl:grid-cols-5 gap-4 overflow-hidden bg-center bg-cover" :style="'background-image:url(/storage/img/events/bg-' + event.slug + '.jpg);'">
                            <div
                                class="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-transparent to-black/50">
                            </div>
                            <div class="relative aspect-square sm:aspect-auto col-span-2 xl:col-span-3 row-span-2 flex flex-col justify-between">
                                <div class="relative">
                                    <div class="absolute top-px left-px max-w-24 max-h-12 opacity-40 blur-sm">
                                        <img class="w-full"
                                            :src="'/storage/img/logos/' + eventPai.slug + '.' + (eventPai.slug == 'mochakk' ? 'png' : 'svg')"
                                            alt="">
                                    </div>
                                    <div class="relative max-w-24 max-h-12 invert">
                                        <img class="w-full"
                                            :src="'/storage/img/logos/' + eventPai.slug + '.' + (eventPai.slug == 'mochakk' ? 'png' : 'svg')"
                                            alt="">
                                    </div>
                                </div>
                                <div class="text-5xl text-white drop-shadow-md font-bold">{{ event.name }}
                            </div>
                            </div>
                            <EventInfoGrid icon="/storage/img/new-icons.svg?file#carbon-choropleth-map" :title="event.cidade"
                                subtitle="Cidade"></EventInfoGrid>
                            <EventInfoGrid icon="/storage/img/new-icons.svg?file#carbon-location" :title="event.venue"
                                subtitle="Venue"></EventInfoGrid>
                            <EventInfoGrid icon="/storage/img/new-icons.svg?file#carbon-user-multiple" :title="event.lotacao"
                                subtitle="Lotação"></EventInfoGrid>
                            <EventInfoGrid icon="/storage/img/new-icons.svg?file#carbon-time" :title="event.horario"
                                subtitle="Horário"></EventInfoGrid>
                        </div>
                        <div class="col-span-5 bg-white">
                            <button v-for="(sessao, indexSessao) in event.sessoes" @click="toggleSelecao(sessao.id)"
                                class="conteudo-mini py-4 w-full flex items-center space-x-4 bg-white/ hover:bg-neutral-100 border-b border-neutral-200"
                                :class="{'!bg-green-500 !text-white shadow-lg': formNewStore.formdata.eventos.includes(sessao.id)}">
                                <div class="flex-none relative size-6 border text-white  border-black/30 bg-white/0 rounded-full flex items-center justify-center transition-all duration-500"
                                    :class="{'!text-white !bg-green-500 border-green-500': formNewStore.formdata.eventos.includes(sessao.id)}">
                                    <transition name="check" mode="out-in">
                                        <svg v-if="formNewStore.formdata.eventos.includes(sessao.id)"
                                            class="check-icon size-6">
                                            <use xlink:href="/storage/img/new-icons.svg?file#carbon-checkmark-filled" /></svg>
                                    </transition>
                                </div>
                                <div class="grow text-left font-bold text-sm transition-all duration-500"
                                    :class="{'!uppercase !text-white': formNewStore.formdata.eventos.includes(sessao.id)}">
                                    {{ sessao.date }}</div>

                            </button>
                        </div>
                        <div class="col-span-5 bg-white conteudo-mini-x flex items-center justify-end">
                            <div
                                class="col-span-5 bg-white flex items-center justify-end py-4 space-x-4 divide-x divide-neutral-300">
                                <button @click="selectAll(event.id)"
                                    class="bg-white hover:font-bold hover:underline py-2">
                                    <div class="text-left text-xs">
                                        Selecionar todas</div>


                                </button>
                                <button @click="unselectAll(event.id)"
                                    class="bg-white hover:font-bold hover:underline py-2 pl-4">
                                    <div class="text-left text-xs">
                                        Desselecionar todas</div>


                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <button @click="valida" class="w-full mt-6 p-6 bg-white flex justify-end items-center space-x-4" :class="{'opacity-50 cursor-default': !preenchidos}">
        <span class="text-xl font-black uppercase">Avançar</span>
        <svg class="size-12 text-black"><use xlink:href="/storage/img/new-icons.svg?file#carbon-arrow-right" /></svg>
    </button>
    <div v-if="!preenchidos" class="text-sm font-bold p-6">Tem de escolher pelo menos uma sessão para poder avançar.</div> -->

    </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { useFormNewStore } from '@/stores/formNew.js'
import { useEventoStore } from '@/stores/eventos.js'

import EventInfoGrid from '../Blocos/EventInfoGrid.vue'

const formNewStore = useFormNewStore();
const eventoStore = useEventoStore();

const toggleSelecao = (eventoId) => {
    const index = formNewStore.formdata.eventos.indexOf(eventoId);
    if (index !== -1) {
        formNewStore.formdata.eventos.splice(index, 1);
    } else {
        formNewStore.formdata.eventos.push(eventoId);
    }
}

const selectAll = (eventoId) => {
    var sessoes = eventoStore.getSessoes(eventoId)
    
    for (let index = 0; index < sessoes.length; index++) {
        const sessao = sessoes[index];
        const existeIndex = formNewStore.formdata.eventos.indexOf(sessao.id);
        if (existeIndex === -1) {
            formNewStore.formdata.eventos.push(sessao.id);
        }
    }
}

const unselectAll = (eventoId) => {
    var sessoes = eventoStore.getSessoes(eventoId)
    
    for (let index = 0; index < sessoes.length; index++) {
        const sessao = sessoes[index];
        const existeIndex = formNewStore.formdata.eventos.indexOf(sessao.id);
        if (existeIndex !== -1) {
            formNewStore.formdata.eventos.splice(existeIndex, 1);
        }
    }
}

console.log('eventosComponent', formNewStore.step.component)

const meses = ref([
   '---',
   'Jan',
    'Fev',
    'Mar',
    'Abr',
    'Mai',
    'Jun',
    'Jul',
    'Ago',
    'Set',
    'Out',
    'Nov',
    'Dez',
])

const valida = () => {

    if(formNewStore.formdata.eventos.length > 0){
        formNewStore.stepNext()
    }




    // formNewStore.step.fields.forEach(field => {
    //     if(!field.valor) field.invalid = true
    // });

    // console.log(formNewStore.steps)

    // axios.post('/submit-form', formNewStore.step.fields).then(() => {
    //     form.processing = false;

    //     console.log()
    //     // closeModal();
    //     // nextTick().then(() => emit('confirmed'));

    // }).catch(error => {
    //     console.log(error.response.data)
        
    //     // form.processing = false;
    //     // form.error = error.response.data.errors.password[0];
    //     // passwordInput.value.focus();
    // });
}

const preenchidos = computed(() => {
    // var campos = formNewStore.step.fields.filter((field) => field.valor)
    // return campos.length
    return formNewStore.formdata.eventos.length > 0
});

formNewStore.step.mensagemErro = computed(() => {
    // var campos = formNewStore.step.fields.filter((field) => field.valor)
    // return campos.length
    return preenchidos.value ? false : 'Não tem qualquer evento/sessão selecionado.'
});
formNewStore.step.valid = computed(() => {
    // var campos = formNewStore.step.fields.filter((field) => field.valor)
    // return campos.length
    return preenchidos.value
});
</script>

<style scoped>
  .text-input {
    @apply block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-green-600
  }
.text-label {
    @apply absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-green-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6
}

.area-icon__wrapper {
    @apply flex size-16 shrink-0 items-center justify-center rounded-full bg-green-500/10;
    .area-icon {
    @apply size-8 text-green-500;
    }
}

  .invalid {
    input,
    .text-input {
        @apply text-red-500 border-red-500 focus:border-red-500
    }
    label,
    .text-label {
        @apply text-red-500 peer-focus:text-red-500
    }

    .area-icon__wrapper {
    @apply bg-red-500/10;
    .area-icon {
    @apply text-red-500;
    }
    }
  }

circle-progress::part(base) {
    @apply w-8 h-auto
}
circle-progress::part(value) {
	stroke-width: 6px;
    @apply stroke-black
}
circle-progress::part(circle) {
	stroke-width: 6px;
    @apply stroke-white
}
</style>